

import { computed, reactive, ref } from 'vue';

// icons
import { location, close, storefrontOutline, homeOutline, arrowForward, closeOutline, walletOutline,
          cardOutline, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol,
        IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
        IonItem, IonLabel, IonList, IonListHeader, IonThumbnail,
        IonInput, IonSelect, IonSelectOption, IonTextarea,
        IonRadioGroup, IonRadio, IonNote, IonSegment, IonSegmentButton, IonAvatar, IonText,
        IonCard, IonCardContent, IonBadge,
        loadingController, isPlatform, onIonViewDidEnter, modalController, } from '@ionic/vue';
import NewCreditCardModal from '@/components/modals/NewCreditCardModal.vue';
import TermsAndConditionsModal from '@/components/modals/TermsAndConditionsModal.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

// services
import OrderService from '@/services/OrderService';

// composables
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';
import config from '@/config';

// services
import UserService from '@/services/UserService';

// libraries
import moment from 'moment';

export default {
  name: 'CheckoutPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol,
                IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
                IonItem, IonLabel, IonList, IonListHeader, IonThumbnail,
                IonInput, IonSelect, IonSelectOption, IonTextarea, 
                IonRadioGroup, IonRadio, IonNote, IonSegment, IonSegmentButton, IonAvatar, IonText,
                IonCard, IonCardContent, IonBadge,
                VueDatePicker, },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const { presentPrompt, presentAlert, openModal, } = utils();
    
    const groupedCartItems = computed(() => store.getters.getGroupedCartItems); // cart items grouped by merchants
    const cartTotal = computed(() => store.getters.cartTotal);
    const loading = computed(() => store.state.loadingUser);
    const pickupPoints = computed(() => store.state.pickupPoints);
    const user = computed(() => store.state.user);
    const settings = computed(() => store.state.settings);
    const order = reactive({
      remark: "",
      customerName: user.value.customerName || user.value.name || "",
      contactPhone: user.value.contactPhone || user.value.phone || "",
      customerEmail: user.value.customerEmail || user.value.email || "",
      totalPrice: null,
      paymentMethod: "",
      stripeCustomerId: user.value.stripeCustomerId || "",

      discountCode: "",
      appliedDiscountCode: "",
      appliedDiscountType: "",
      appliedDiscountValue: 0,

      submittingOrder: false,
      checkingDiscountCode: false,
    });
    const paymentProofFile = ref<any>(null);
    const paymentProofDataURL = ref("");

    const stripePaymentMethods = ref([]);

    /**
     * Google Pay (Phase 2)
    const options = {
      requestPayerName: true,
      requestPayerEmail: true,
      currency: 'hkd',
      country: 'HK',
      total: {
        label: 'Demo total',
        amount: 1000,
      }
    };
    console.log(options);
    const paymentRequest = res.paymentRequest(options);
    const prButton = elements.create('paymentRequestButton', { paymentRequest });

    paymentRequest.canMakePayment().then(result => {
      console.log(result);
      if (result) {
        prButton.mount(googlePayBtn.value);
        prCanMakePayment.value = true;
      }
    });

    paymentRequest.on('paymentmethod', async (e) => {
      console.log("onPaymentMethod");
      console.log(e);
      const { error: backendError, clientSecret } = await OrderService.createPaymentIntent('hkd', 1000, 'card');
      if (backendError) {
        console.error(backendError);
        e.complete('fail');
        return;
      }
      const { error, paymentIntent } = await stripe.value.confirmCardPayment(
        clientSecret,
        {
          'payment_method': e.paymentMethod.id,
        },
        {
          handleActions: false,
        }
      );
      console.log(paymentIntent);
      //console.log(paymentIntent.id);
      if (error) {
        console.error(error);
        e.complete('fail');
        return;
      }
      e.complete('success');
      if (paymentIntent.status === 'requires_action') { // Let Stripe.js handle the rest of the payment flow.
        const {error, paymentIntent} = await stripe.value.confirmCardPayment(clientSecret);
        if (error) {
          console.error(error);
          return;
        }
      }
    });
      */

    /**
     * Discounts
     */
    const applyDiscountCode = async () => {
      order.checkingDiscountCode = true;
      const res = await OrderService.getDiscount(order.discountCode);
      order.checkingDiscountCode = false;

      if (res) { // discount code exists
        order.appliedDiscountCode = res.code;
        order.appliedDiscountType = res.type;
        order.appliedDiscountValue = res.type == "fixed_amount" ? res.fixedAmountValue : res.percentageValue;
      }
      else { // invalid discount code
        order.discountCode = "";
        presentAlert(t('CheckoutPage.invalidDiscountCode'));
      }
    }
    const resetDiscountCode = () => {
      order.discountCode = "";
      order.appliedDiscountCode = "";
      order.appliedDiscountType = "";
      order.appliedDiscountValue = 0;
    }
    const getDiscountTotal = () => {
      let vipUserDiscount = 0;
      if (user.value.level == "VIP會員") {
        vipUserDiscount = cartTotal.value * Number(settings.value.vipDiscountPercentage);
      }
      if (order.appliedDiscountCode == "") return vipUserDiscount;
      if (order.appliedDiscountType == "fixed_amount") return Math.min(cartTotal.value-vipUserDiscount, order.appliedDiscountValue) + vipUserDiscount;
      return cartTotal.value - cartTotal.value * (1-order.appliedDiscountValue) * (1-settings.value.vipDiscountPercentage); // percentage discount
    }

    /**
     * Submit Order
     */
    const getShippingTotal = () => (Object.values(groupedCartItems.value).reduce((total: any, obj: any) => total + Number(obj.merchant.shippingTotal || 0), 0));
    const getOrderTotal = () => (Number(getShippingTotal()) + Number(cartTotal.value) - Number(getDiscountTotal()));
    const submitOrder = async (order: any) => {
      const dismissLoading = () => {
        loadingController.dismiss();
        order.submittingOrder = false;
      }
      presentPrompt(t('CheckoutPage.confirmOrder'), "", async () => {
        order.submittingOrder = true;
        const loading = await loadingController.create({});
        await loading.present();
        order.discountTotal = getDiscountTotal();
        order.totalPrice = getOrderTotal();
        const res = await OrderService.createNewOrder(order, groupedCartItems.value, paymentProofFile.value);
        console.log(res);
        if (!res.success) {
          dismissLoading();
          if (res.errType == 'serverBusy') {
            presentAlert(t('serverBusy')); // Server Busy
          }
          else if (res.errType == 'serverError') {
            presentAlert(t('serverError')); // Server Error
          }
          else if (res.errType == 'invalidDiscountCode') { // Invalid discount code (out of stock)
            resetDiscountCode();
            presentAlert(t('CheckoutPage.invalidDiscountCode'));
          }
          return false;
        }

        // Sync user last input order data (user table)
        const { customerName, contactPhone, customerEmail, } = order;
        const updatedUserObj = { customerName, contactPhone, customerEmail, };
        for (const val of Object.values(groupedCartItems.value)) {
          const obj: any = val;
          if (Object.keys(obj.groupedDeliveryOptions).length == 0) {
            if (obj.merchant.deliveryAddress) { // save last filled delivery address
              updatedUserObj['deliveryAddress'] = obj.merchant.deliveryAddress;
              break;
            }
          }
        }
        UserService.updateLoggedInUser(updatedUserObj, {});
        store.commit('updateUser', updatedUserObj);

        // Refresh user list of orders & purchased items (including available points)
        store.dispatch('getUserData', { resetCartItems: true });

        // Redirect to ThankYouPage
        router.replace({ name: 'ThankYouPage' });
        dismissLoading();
      });
    }
    const onPaymentProofChange = (e: any) => {
      if (e.target.files && e.target.files[0]) {
        paymentProofFile.value = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          paymentProofDataURL.value = e.target.result;
        }
        reader.readAsDataURL(paymentProofFile.value); // convert to base64 string and preview it
        e.srcElement.value = null;
      }
    }
    const clearUploadedPaymentProof = () => {
      paymentProofDataURL.value = "";
      paymentProofFile.value = null;
    }

    /**
     * Delivery related methods
     */
    const onPickupPointChanged = (obj: any) => {
      obj.merchant.pickupPointObj = pickupPoints.value.find((p: any) => p.id == obj.merchant.pickupPoint);
      obj.deliveryAddress = obj.merchant.pickupPointObj ? obj.merchant.pickupPointObj.address : "";
    }
    const getDeliveryOptions = (groupedDeliveryOptions: any, region: any) => {
      const options = [];
      for (const group in groupedDeliveryOptions[region]) {
        for (const price in groupedDeliveryOptions[region][group]) {
          const districts = groupedDeliveryOptions[region][group][price];
          const label = `${group}：${districts.map(d => d.nameChi).join(" , ")}`;
          options.push({ region, group, price, label });
        }
      }
      return options;
    }
    const syncMerchantShippingTotal = (obj: any) => {
      const { deliveryDistrict, deliveryMethod, deliveryRegionFilter } = obj.merchant;
      if (deliveryDistrict && deliveryMethod == '送貨上門') {
        const options = getDeliveryOptions(obj.groupedDeliveryOptions, deliveryRegionFilter)
        const selectedOpt = options.find(opt => opt.label == deliveryDistrict);
        obj.merchant.shippingTotal = selectedOpt.price;
      } else {
        obj.merchant.shippingTotal = 0;
        if (deliveryMethod == '自取') onPickupPointChanged(obj); // may have selected a pickup point by default
      }
    }
    const getDeliveryTimeSlots = (obj: any) => {
      const getTimeSlots = (startHour: any, endHour: any, duration: any) => {
        const slots = [];
        for (let h = moment(startHour, 'H'); h.isBefore(moment(endHour, 'H')); h.add(duration || 60, 'm')) {
          const endTime = moment(h).add(duration || 60, 'm');
          const timeSlot = `${h.format('HH:mm')} - ${endTime.format('HH:mm')}`;
          slots.push({ text: timeSlot, value: timeSlot });
        }
        return slots;
      }
      const { merchant } = obj;
      const { deliveryStartTime, deliveryEndTime, deliveryDate, deliveryMethod, timeSlotDuration } = merchant;

      if (deliveryMethod == '送貨上門') { // get timeslots according to merchant delivery start time & end time
        if (deliveryStartTime && deliveryEndTime) {
          return getTimeSlots(deliveryStartTime, deliveryEndTime, timeSlotDuration);
        }
      } else if (deliveryMethod == '自取' && deliveryDate) { // get timeslots based on delivery date day of week & pickup point opening hours
        const pointObj = obj.merchant.pickupPointObj;
        if (pointObj) {
          const fields = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
          const dayOfWeek = fields[moment(deliveryDate).day()]; // day of week of the delivery date
          return getTimeSlots(pointObj[`${dayOfWeek}OpeningTime`], pointObj[`${dayOfWeek}ClosingTime`], timeSlotDuration);
        }
      }
      return [];
    }
    const getDisabledDaysOfWeek = ({ merchant }) => {
      return merchant.pickupPointObj ? merchant.pickupPointObj.closingDaysOfWeek.split(' , ') : [];
    }

    // INIT
    onIonViewDidEnter(() => {
      if (user.value.id) {
        if (user.value.stripeCustomerId) {
          OrderService.retrievePaymentMethods(user.value.stripeCustomerId).then(res => {
            stripePaymentMethods.value = res;
          })
        }
      }
    })
    
    return {
      // icons
      location, close, storefrontOutline, homeOutline, arrowForward, closeOutline, walletOutline,
      cardOutline,
      
      // variables
      loading, groupedCartItems, order, submitOrder,
      paymentProofFile, paymentProofDataURL,
      cartTotal, pickupPoints,

      //googlePayBtn, prCanMakePayment,

      // methods
      t,
      getShippingTotal, getOrderTotal,
      onPaymentProofChange, clearUploadedPaymentProof,
      onPickupPointChanged, getDeliveryOptions, syncMerchantShippingTotal,
      getDeliveryTimeSlots, getDisabledDaysOfWeek,
      getDiscountTotal, applyDiscountCode, resetDiscountCode,

      // Stripe Payment Methods
      stripePaymentMethods,
      filteredPaymentMethods: () => {
        const { enabledPaymentMethods, onlinePaymentMethods } = settings.value;
        return enabledPaymentMethods ? config.paymentMethods.filter(m => enabledPaymentMethods.split(' , ').includes(m.value)) : config.paymentMethods;
      },
      openNewCreditCardModal: async () => {
        const modal = await modalController.create({ component: NewCreditCardModal });
        modal.onDidDismiss().then(async ({ data }) => {
          console.log(data);
          if (data && data.paymentMethod) {
            const { paymentMethod, stripeCustomerId } = data;
            stripePaymentMethods.value.push(paymentMethod);
            order.paymentMethod = paymentMethod.id;

            if (stripeCustomerId) { // Stripe customer created, link to user
              order.stripeCustomerId = stripeCustomerId;
              store.commit('updateUser', { stripeCustomerId });
            }
          }
        });
        return modal.present();
      },
      openTCModal: async () => await openModal(TermsAndConditionsModal, {}),
    }
  },
}

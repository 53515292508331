<template>
  <page-header :showCloseModal="true" :title="t('addNewCreditCard')">
  </page-header>

  <ion-content>
    <ion-grid fixed>

      <div class="ion-padding">

        <form action="/charge" method="post" id="payment-form">
          <div class="form-row">
            <div ref="cardElement">
              <!-- A Stripe Element will be inserted here. -->
            </div>
            <!-- Used to display form errors. -->
            <div id="error-msg" ref="cardErrors" role="alert"></div>
          </div>
        </form>
        
        <input ref="inviInput" type="tel" style="border: 0px; color: transparent; width: 0px; height: 0px; background: transparent;">

        <div style="padding-bottom: 16px; font-size: 14px">
          <ion-checkbox slot="start" label-placement="end" :modelValue="isSaveCreditCard"
                        @update:modelValue="isSaveCreditCard = $event">{{ t('saveCreditCard') }}</ion-checkbox>
        </div>

        <ion-button expand="block" @click="addNewCard()">
          {{ t('done') }}
        </ion-button>
      </div>

    </ion-grid>
  </ion-content>
</template>

<script>
// Vue reactivity
import { computed, defineComponent, onMounted, ref, } from 'vue';

// icons
import { arrowBack, pricetagOutline, giftOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent,
        IonGrid, IonList, IonItem, IonLabel, IonInput, IonCard, IonCheckbox,
        isPlatform, loadingController, } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// services
import OrderService from '@/services/OrderService';
import UserService from '@/services/UserService';
import { loadStripe } from '@stripe/stripe-js';

export default defineComponent({
  name: 'NewCreditCardModal',
  props: ["stripeCustomerId"],
  components: { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent,
                IonGrid, IonList, IonItem, IonLabel, IonInput, IonCard, IonCheckbox, },
  setup(props) {
    const router = useRouter();
    const { t, locale } = useI18n();
    const { closeModal, presentAlert, } = utils();

    // Store
    const store = useStore();
    const user = computed(() => store.state.user);
    const settings = computed(() => store.state.settings);

    // Stripe
    const stripe = ref(null);
    const card = ref(null);
    const cardElement = ref(null);
    const cardErrors = ref(null);
    const inviInput = ref(null); // FOR FIXING IOS KEYBOARD PROBLEM
    const isSaveCreditCard = ref(true); // default save credit card for next time use

    const cardInputStyles = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }

    onMounted(() => {
      setTimeout(() => {
        //loadStripe("pk_test_51HVue8F5eqkgmghRoS3cNn8SMdn9ou9PIffiirbX1a4BvdgPSgCGHWvX6EO4XQF6ppPZdkFxyJHE8MmwGs2aby8P00g5qBbCG0").then((res) => {
        loadStripe(settings.value.stripePubKey).then((res) => {
          stripe.value = res;
          const elements = res.elements();
          card.value = elements.create('card', { disableLink: true, hidePostalCode: true, style: cardInputStyles }); // Create an instance of the card Element.
          card.value.mount(cardElement.value); // Add an instance of the card Element into the `card-element` <div>.

          // Handle real-time validation errors from the card Element.
          card.value.on('change', (ev) => {
            cardErrors.value.textContent = ev.error ? ev.error.message : '';
          });

          // iOS bug fix
          if (isPlatform('ios')) {
            card.value.on('blur', (e) => {
              inviInput.value.focus();
              setTimeout(() => inviInput.value.blur());
            })
          }
        });
      }, 250)
    })

    return {
      // icons
      arrowBack, pricetagOutline, giftOutline,

      // variables
      cardElement, cardErrors, inviInput,
      isSaveCreditCard,

      // methods
      t, closeModal,

      addNewCard: async () => {
        const loading = await loadingController.create({ duration: 30000 });
        await loading.present();

        const { paymentMethod, error } = await stripe.value.createPaymentMethod({
          type: 'card',
          card: card.value,
        });
        if (paymentMethod) {
          // 1. Check card validity
          const res = await OrderService.verifyPaymentMethod(user.value, paymentMethod.id);
          const { success, errMsg, stripeCustomerId } = res;
          if (success == true) {
            // 2. Check detach card from customer
            if (!isSaveCreditCard.value) {
              OrderService.detachPaymentMethod(paymentMethod.id);
            }

            // 3. Return data & close modal
            closeModal({ paymentMethod, stripeCustomerId });
          }
          else {
            presentAlert(errMsg);
          }
        }
        loading.dismiss();
      }
    }
  }
})
</script>

<style scoped>
  #error-msg {
    padding-top: 8px;
    color: var(--ion-color-danger);
  }

  .StripeElement {
    box-sizing: border-box;

    height: 40px;

    padding: 10px 12px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
</style>